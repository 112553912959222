import { useQuery } from '@tanstack/react-query';
import { Flex, Select, Typography } from 'antd';
import { ReactNode } from 'react';
import { getUsersAvailableAssignDropdown } from 'src/apis/master_data/userTicketCategory.api';
import { SelectProps } from 'src/types/elements.type';
const { Text } = Typography;

type Props = SelectProps & {
  ticketCategory: number;
  placeholder?: ReactNode;
  departmentId?: number;
};

export default function UserAvailableAssignSelect({
  value,
  style,
  allowClear = true,
  onChange,
  placeholder,
  ticketCategory,
  departmentId
}: Props) {
  const usersAvailableAssignQuery = useQuery({
    queryKey: ['usersAvailableAssignDropdown', ticketCategory, departmentId],
    queryFn: () => getUsersAvailableAssignDropdown(ticketCategory, departmentId as number),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={usersAvailableAssignQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={usersAvailableAssignQuery.data?.data?.map((item) => ({
        value: item.id,
        label: (
          <Flex gap={4}>
            <Text>{item.name}</Text>
            {item?.ticketCodes?.length > 0 && (
              <Text type='secondary'>
                (Đang xử lý {<Text type='secondary'>{item?.ticketCodes?.length}</Text>} ticket khác)
              </Text>
            )}
          </Flex>
        )
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={usersAvailableAssignQuery.isFetching}
    />
  );
}
